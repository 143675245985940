
input.mobile_menu {
  position: fixed;
  opacity: 0;
}

.mobile-menu-label {
  position: absolute;
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
}
.mobile-menu-label::before {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(10, 38, 57, 0.75);
  content: "";
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  background-image: url('../images/soccer/odd1.png');
  background-position: 20px;
  background-repeat: no-repeat;

}
.mobile-menu-label .burger {
  position: fixed;
  top: 23px;
  right: 1em; 
  /*  energie*/
  z-index: 999999999999999999;
  width: 2em;
  height: 2em;
  margin: 0;
  padding: 0;
  transition: opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
.mobile-menu-label .burger::before,
.mobile-menu-label .burger .bar, .mobile-menu-label .burger::after {
  position: absolute;
  left: 0;
  display: block;
  width: 100%;
  height: 12%;
  background: white;
  content: "";
  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
.mobile-menu-label .burger .bar {
  top: 44%;
}
.mobile-menu-label .burger::before {
  top: 0;
  transform-origin: top left;
}
.mobile-menu-label .burger::after {
  bottom: 0;
  transform-origin: bottom left;
}

input:focus + .mobile-menu-label .burger,
.mobile-menu-label:hover .burger {
  opacity: 0.75;
}

nav {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 999999999999999999;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  transform: translate3d(0, 0, 0);
  /* material acceleration */
  transform: translateX(-100%);
  will-change: transform;
  transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
nav::before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 25em;
  background: #d62956; /*#d62956*/
  content: "";
  transform: skewX(15deg) translateX(-100%);
  transform-origin: bottom left;
  will-change: transform;
  transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
@media (min-width: 40em) {
  nav::before {
    width: 40em;
  }
}
nav a {
  margin: 0.5em 0;
  padding: 0.2em 2em;
  font-size: 1.5em;
  color: white;
  text-decoration: none;
  font-weight: 500;
  transform: translateX(-100%);
  transition: color 0.15s, transform 0.5s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
nav a:nth-child(1) {
  transition-delay: 0s, 100ms;
}
nav a:nth-child(2) {
  transition-delay: 0s, 150ms;
}
nav a:nth-child(3) {
  transition-delay: 0s, 200ms;
}
nav a:nth-child(4) {
  transition-delay: 0s, 250ms;
}
nav a:nth-child(5) {
  transition-delay: 0s, 300ms;
}
nav a:nth-child(6) {
  transition-delay: 0s, 350ms;
}
nav a:hover, nav a:focus {
  color: black;
}

main {
  overflow: hidden;
}
main .content {
  transform: translate3d(0, 0, 0);
  /* material acceleration */
  will-change: transform, filter;
  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}

[id=navcheck]:checked + .mobile-menu-label::before {
  opacity: 1;
  pointer-events: auto;
}
[id=navcheck]:checked + .mobile-menu-label .burger::before, [id=navcheck]:checked + .mobile-menu-label .burger::after {
  width: 141.42%;
}
[id=navcheck]:checked + .mobile-menu-label .burger::before {
  transform: rotate(45deg) translateY(-50%);
}
[id=navcheck]:checked + .mobile-menu-label .burger::after {
  transform: rotate(-45deg) translateY(50%);
}
[id=navcheck]:checked + .mobile-menu-label .burger .bar {
  transform: scale(0.1);
}
[id=navcheck]:checked ~ nav {
  transform: translateX(0);
}
[id=navcheck]:checked ~ nav::before {
  transform: skewX(15deg) translateX(0);
}
[id=navcheck]:checked ~ nav a {
  transform: translateX(0);
}
[id=navcheck]:checked ~ main .content {
  transform: translateX(3em);
  transform-origin: left center;
  filter: blur(2px);
}

/* helper */
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

article {
  width: 80%;
  margin: 0 auto;
  font-size: 1.3em;
}
@media (min-width: 60em) {
  article {
    width: 50%;
  }
}

h1 {
  font: 2.5em/1.4 "roboto condensed";
  font-weight: 700;
}

