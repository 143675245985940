
body{
  background: #f2f3f8;
}

.white-color-1{
  color: #000 !important;
}


.unchanged-white-color-1{
  color: #fff !important;
}


/*all dark level = 4... lighter to the end*/

.dark_bg_1{
  background-color: #9c0233 !important;
}
.dark_text_1{
  color: #202a39;
}


.dark_bg_2{
  background-color: #141c33 !important;
}
.dark_text_2{
  color: #141c33;
}


.dark_text_3{
  color: #283352;
}
.dark_bg_3{
  background-color: #283352 !important;
}

.dark_text_4{
  color: #868ea7 !important;
}
.dark_bg_4{
  background-color: #868ea7 !important;
}

.dark_bg_5{
  background-color: #fff !important;
}


.orange-bg-color-1{
  background-color: #ff6600 !important;
}

.orange-bg-color-2{
  
  background-color: #544a40 !important;
  background: #544a40 !important;

  /*background-color: #9c0233 !important;
  background: #9c0233 !important;*/
}

.red-bg-1{
  background-color: #9c0233 !important;
}

.red-text-1{
  color: #9c0233 !important;
}

.header-boxed-menu-bg{
  background-color: #fff !important;
  color: #000;
}
.header-boxed-menu-text{
  color: #000 !important;
}

.main-bg-color-1{
  background-color: #f2f3f8 !important;
}

.gold-gradient-1{
  /*  background: linear-gradient(to bottom, #c9b298 0%, #7f6c49 100%) !important;*/
  background-color: #9c0233 !important;
  border-radius: 4px !important;
}


input[type="text"] {
  border: 0.5px solid #000 !important; /* Add a border for better contrast */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2); /* Adjust the shadow values to your liking */

}


/*authentication place*/
.signin_validate label{
  color: #fff !important;
}

.auth-form ::placeholder, .auth-form option {
  color: #bfc1ca !important;
}

.auth-icon-color{
  color: #000 !important;
  margin-left: 10px;
}


